import { useMatch } from 'react-router-dom';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { BoostingLimitReachedModal } from '../ad/variants/BoostingLimitReachedModal';
import {
  hasBoostingLimitReachedModalShowedSelector,
  hasSuperboostLimitReachedModalShowedSelector,
  showBoostedPropertiesLimitReachedModalSelector,
  showSuperboostingLimitReachedModalSelector,
} from '../ad/selectors';
import {
  OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY,
  OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL,
  UPSELL_BOOSTING_MODAL,
  UPSELL_SUPERBOOST_MODAL,
} from '../ad/events';
import { UpsellSuperBoostingLimitReachedModal } from '../ad/variants/UpsellSuperboostingLimitReachedModal';

export default function PublisherLimitReachedOffersModal() {
  const isInPropertiesPage = useMatch('/properties*');
  const isInOverviewPage = useMatch('/');
  const isInEnquiriesPage = useMatch('/enquiries*');
  const isInLeadPage = useMatch('/leads*');

  const showSuperboostModal = useSelector(
    showSuperboostingLimitReachedModalSelector
  );
  const showBoostingModal = useSelector(
    showBoostedPropertiesLimitReachedModalSelector
  );
  const hasSuperboostLimitReachedModalShowed = useSelector(
    hasSuperboostLimitReachedModalShowedSelector
  );
  const hasBoostingLimitReachedModalShowed = useSelector(
    hasBoostingLimitReachedModalShowedSelector
  );

  let pageName = '';
  if (isInPropertiesPage) {
    pageName = 'properties';
  }
  if (isInOverviewPage) {
    pageName = 'overview';
  }
  if (isInEnquiriesPage) {
    pageName = 'inbox';
  }
  if (isInLeadPage) {
    pageName = 'leads';
  }

  useEffect(() => {
    if (
      !isInLeadPage &&
      !isInEnquiriesPage &&
      !isInOverviewPage &&
      !isInPropertiesPage
    ) {
      return;
    }
    if (showBoostingModal && !hasBoostingLimitReachedModalShowed) {
      dispatch({
        id: OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY,
        payload: {
          section: `upsell-boosting-modal-${pageName}-page`,
        },
      });
    }
    if (showSuperboostModal && !hasSuperboostLimitReachedModalShowed) {
      dispatch({
        id: OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL,
        payload: {
          section: `upsell-superboost-modal-${pageName}-page`,
        },
      });
    }
  }, [
    showSuperboostModal,
    hasSuperboostLimitReachedModalShowed,
    showBoostingModal,
  ]);

  if (
    !isInLeadPage &&
    !isInEnquiriesPage &&
    !isInOverviewPage &&
    !isInPropertiesPage
  ) {
    return null;
  }

  return (
    <>
      <BoostingLimitReachedModal
        dialog={UPSELL_BOOSTING_MODAL}
        section={`upsell-boosting-modal-${pageName}-page`}
        showOnlyOffers
      />
      <UpsellSuperBoostingLimitReachedModal
        dialog={UPSELL_SUPERBOOST_MODAL}
        section={`upsell-superboost-modal-${pageName}-page`}
      />
    </>
  );
}
